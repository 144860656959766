.intro{
  background: rgb(32,19,70);
  background: linear-gradient(180deg, rgba(32,19,70,1) 0%, rgba(84,19,70,1) 50%, rgba(232,59,104,1) 125%);
  width: 100%;
  height: 100vh;
  position: relative;
  overflow: hidden;

  &__container{
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__title{
    text-transform: uppercase;
    font-size: 3rem;
    font-family: $font;
    font-weight: bold;
    color: $white;
    position: fixed;
    z-index: 1;
    top: 30%;
    width: 70%;
    text-align: center;
    @media screen and (max-width: 700px){
      font-size: 1.5rem;
      top: 25%;
      width: 80%;
    }
  }

  &__hours{
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    position: fixed;
    top: 50%;
    width: 100%;

    @media screen and (max-width: 700px){
      flex-direction: column;
      bottom: 25%;
    }

    >div{
      display: flex;
      img{
        width: 120px;
        @media screen and (max-width: 700px){
          width: 80px;
        }
      }
    }

    >div >div{
      display: flex;
      flex-direction: column;
      animation: show 3s;
      span:first-child {
        font-size: 30px;
      }
      @media screen and (max-width: 700px){
        span{
          width: 100%;
          display: block;
        }
      }
    }

    &__iss,&__local{
      margin-right: 20px;
      text-align: center;
      @media screen and (max-width: 700px){
        width: 80%;
        margin: 0 auto;
      }
    }

    span{
      color: $white;
      font-family: $font;
      font-size: 60px;
      min-width: 260px;
      @media screen and (max-width: 700px){
        font-size: 2rem;
      }
    }
  }

  &__bottom{
    width: 100%;
    height: 30vh;
    position: absolute;
    bottom: -2px;
    background-image: url('/assets/images/bas.svg');
    background-repeat: no-repeat;
    background-size: 125%;
    background-position-x: -9rem;
    z-index: 2;
    background-position-y: bottom;
    @media screen and (max-width: 700px){
      background-size: 170%;
    }
  }

  &__fond{
    background-image: url("/assets/images/fond.svg");
    background-attachment: fixed;
    background-size: cover;
    background-repeat: no-repeat;
    width: 100%;
    height: 100vh;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 0;
  }

  &__etoile1{
    background-image: url("/assets/images/etoile1.svg");
    background-repeat: no-repeat;
    background-size: cover;
    position: absolute;
    top: 200px;
    right: 30%;
    width: 60px;
    height: 60px;
    animation: etoile1 70s infinite;
  }
  &__etoile2{
    background-image: url("/assets/images/etoile2.svg");
    background-repeat: no-repeat;
    background-size: cover;
    position: absolute;
    top: 200px;
    right: 30%;
    width: 60px;
    height: 60px;
    animation: etoile2 50s infinite;
  }
  &__etoile3{
    background-image: url("/assets/images/etoile3.svg");
    background-repeat: no-repeat;
    background-size: cover;
    position: absolute;
    top: 0;
    right: -100%;
    width: 60px;
    height: 60px;
    animation: etoile3 10s;
  }
}
@keyframes etoile1 {
  from {
    top: 0;
    right: 0;
    opacity: 1;
  }

  to {
    top: 100%;
    right: 100%;
    opacity: 0.7;
  }
}
@keyframes etoile2 {
  from {
    top: 0;
    right: 30%;
    opacity: 1;
  }

  to {
    top: 100%;
    right: 100%;
    opacity: 0.7;
  }
}
@keyframes etoile3 {
  from {
    top: 25%;
    right: 0;
    opacity: 1;
  }

  to {
    top: 100%;
    right: 100%;
    opacity: 0.7;
  }
}
@keyframes show {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}