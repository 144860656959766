.mapZone{
  z-index: 3;
  position: relative;
  background-color: $black;
  min-height: 500px;
  &:after{
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: transparent;
    top:0;
    left: 0;
  }
  @media screen and (max-width: 700px){
    canvas{
      min-height: 300px;
    }
  }
  &__degrade{
    height: 240px;
    width: 100%;
    background: linear-gradient(0deg, $black 45%, $purple1 100%);

    h2{
      color: $white;
      text-align: center;
      font-family: $font;
      font-size: 2.2rem;
      padding-top: 4em;
      @media screen and (max-width: 700px){
        padding-top: 8.5rem;
        font-size: 1.6rem;
        width: 85%;
        margin: 0 auto;
      }
    }
  }
  div[data-testid="resium-container"]{
    position: relative;
    max-width: 1200px;
    margin: 0 auto;
    @media screen and (max-width: 700px){
      min-height: 375px;
    }
    > p.vitesse{
      position: absolute;
      color: $white;
      font-family: $font;
      font-size: 5rem;
      bottom: 2%;
      right: 4rem;
      @media screen and (max-width: 700px){
        font-size: 3rem;
        bottom: 0;
        right: 1.5rem;
      }
      span{
        font-size: 3rem;
        @media screen and (max-width: 700px){
          font-size: 1.5rem;
        }
      }
    }
    >div.infosMeteo{
      position: absolute;
      @media screen and (min-width: 700px){
        top: 0;
      }
      @media screen and (max-width: 700px) {
        bottom: -0.5rem;
        width: 20%;
        left: 1.5rem;
        img{
          width: 100%;
        }
      }
      p{
        color: $white;
        text-align: center;
        font-family: $font;
        transform: translateY(-30px);
        font-size: 2rem;
        @media screen and (max-width: 700px) {
          font-size: 1.5rem;
          transform: translateY(-10px);
        }
      }
      pre{
        font-size: 1rem;
        font-family: $font;
        padding-top: 0.5rem;
      }
    }
  }
  .cesium-viewer-bottom{
    display: none;
  }
}

