.App{
  position: relative;
}
.animation-container{
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: 100;
  background-color: $white;

  &.hide{
    animation: hide 3s;
    opacity: 0;
  }
}

@keyframes hide {
  from{
    opacity: 1;
  }
  to{
    opacity: 0;
  }
}