.header{
  position: fixed;
  z-index: 15!important;
  height: 80px;
  width: 100%;
  background-color: $black;
  color: $white;

  @media screen and (max-width: 700px){
    height: 60px;
  }

  &__content{
    display: flex;
    justify-content: space-between;
    padding: 1rem 4rem;
    max-width: 1200px;
    margin: 0 auto;
    position: relative;
    z-index: 1;
    height: 100%;

    @media screen and (max-width: 700px){
      padding: 1rem;
    }

    img{
      z-index: inherit;
      cursor: pointer;
    }

    &__links{
      display: flex;
      justify-content: center;
      align-items: center;
      @media screen and (max-width: 700px){
        position: absolute;
        background:$black;
        width: 100%;
        left: -100%;
        top: 0;
        height: 100vh;
        flex-direction: column;
        justify-content: space-around;
        padding: 35vh 0;
        transition: all 0.4s ease;
        &.open{
          left: 0;
        }
        &.close{
          left: -100%;
        }
      }
      a{
        margin-left: 2rem;
        font-family: $font;
        cursor: pointer;
        padding: 0.9rem;
        transition: all 0.3s ease;
        @media screen and (max-width: 700px){
          margin: 0;
        }
        &:hover{
          background-color: $purple1;
          color: $white;
        }
      }
    }
  }
}
.hamburger-react{
  transform: translateY(-10px)!important;
  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
  &:focus{
    outline: none!important;
    outline-width:0px!important;
  }
}