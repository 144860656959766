.footer{
  width: 100%;
  height: 70px;
  background-color: $black;
  z-index: 3;
  position: relative;
  @media screen and (max-width: 700px){
    height: fit-content;
    padding: 1.9rem 0 1rem 0;
  }
  &__container{
    color: $white;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: $font;
    height: 100%;
    position: relative;
    @media screen and (max-width: 700px){
      flex-direction: column;
      padding: 0;
    }
  }
  a{
    margin-left: 2rem;
    cursor: pointer;
    padding: 0.9rem;
    background-color: $black;
    transition: all 0.3s ease;
    @media screen and (min-width: 700px){
      &:hover{
        background-color: $purple1;
        color: $white;
      }
    }
    -webkit-tap-highlight-color: transparent;
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Old versions of Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
    &:focus{
      outline: none!important;
      outline-width:0px!important;
    }
    @media screen and (max-width: 700px){
      margin: 0;
      text-align: center;
    }
  }
  &__links{
    @media screen and (max-width: 700px){
      display: flex;
      flex-direction: column;
    }
  }
  img{
    height: 50%;
    cursor: pointer;
    max-height: 40px;
    @media screen and (max-width: 700px){
      margin-bottom: 0.9rem;
    }
  }
}