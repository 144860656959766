*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  scroll-behavior: smooth;
  outline:none;
}
.container{
  width: 100%;
  max-width: 1200px;
  height: 100%;
  margin: 0 auto;
}