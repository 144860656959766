.equipage{
  width: 100%;
  z-index: 3;
  position: relative;
  background-color: $purple1;
  &__degrade{
    height: 150px;
    background: linear-gradient(0deg, $purple1 14%, $black 94%);
    @media screen and (max-width: 700px){
      height: 160px;
      background: linear-gradient(0deg, $purple1 0%, $black 65%);
    }
  }
  &__title{
    color: $white;
    font-family: $font;
    font-size: 2rem;
    text-align: center;
    padding-bottom: 3rem;
    @media screen and (max-width: 700px){
      font-size: 1.6rem;
      width: 85%;
      margin: 0 auto;
    }
  }
  &__container{
    background-color: $purple1;
  }
  &__members{
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-wrap: wrap;
    width: 80%;
    margin: 0 auto;
    .react-reveal{
      width: 200px;
      @media screen and (max-width: 700px){
        width: 100%;
      }
    }
    &__single{
      border: 2px solid $white;
      margin-bottom: 3rem;
      color: $white;
      font-family: $font;
      background-color: $purple1;
      overflow: hidden;
      position: relative;
      box-shadow: -12px 12px 0px 0px $purple2;
      transition: all 1s ease;


      &--front{
        width: 200px;
        min-width: 21%;
        height: 260px;
        display: flex;
        align-items: center;
        justify-content: space-evenly;
        flex-direction: column;
        @media screen and (max-width: 700px){
          margin: 0;
          width: 100%;
          height: 100%;
        }
      }

      @media screen and (min-width: 700px){
        &:hover{
          z-index: 100;
          transform: scale(1.1);
          transition: all 1s ease;
          cursor: pointer;
        }
      }

      @media screen and (max-width: 700px){
        width: 90%;
        margin-left: auto;
        margin-right: auto;
        height: 300px;
      }
      .photo{
        width: 150px;
        height: 150px;
        border-radius: 150px;
        overflow: hidden;
        display: flex;
        justify-content: center;
        img{
          object-fit: cover;
          object-position: top;
        }
        img.custom-img{
          width: auto;
          height: 100%;
          object-fit: cover;
          object-position: bottom;
          text-align: center;
        }
      }
      .infos{
        display: flex;
        justify-content: space-evenly;
        align-items: center;
        flex-direction: column;
        width: 130%;
        >div{
          display: flex;
          align-items: center;
          justify-content: space-evenly;
          img{
            height: 20px;
            margin-right: 1rem;
            margin-top: 5px;
          }
        }
        p{
          @media screen and (max-width: 700px){
            font-size: 1.3rem;
            margin-top: 0.5rem;
          }
        }
      }
      img{
        width: 100%;

      }
    }
  }
}